// JoinUs/SignIn drawer
import React, { useEffect } from 'react';
import axios, { API } from 'config/axios';
import { withTranslation } from 'config/i18n';
// Material UI Core
import {
  makeStyles, darken, withStyles, alpha,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Link from 'components/shared/Link';
import Button from '@material-ui/core/Button';
// Material ui icons
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
// React component
import { useLayoutSnackbar } from 'components/shared/Layout';
import useHandleResponse from 'utils/useHandleResponse';
import Alert from '@material-ui/lab/Alert';
import useSSONavigate from 'common/hooks/useSSONavigate';
import SocialLogin from 'common/components/SocialLogin';

const drawerWidth = '21.5rem';
const mobileDrawerWidth = '20rem';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& [class*='MuiBackdrop-root']": {
      backgroundColor: 'rgba(0,0,0,0.16)',
    },
    [theme.breakpoints.down('md')]: {
      width: mobileDrawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down('md')]: {
      width: mobileDrawerWidth,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 1.5rem 0 2rem',
    backgroundColor: [theme.palette.grey[700]],
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    minHeight: ['5rem', '!important'],
    [theme.breakpoints.down('sm')]: {
      minHeight: ['3.5rem', '!important'],
    },
    "& [class*='MuiTypography-root']": {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(20),
    },
  },
  grow: {
    flexGrow: 1,
  },
  form: {
    backgroundColor: [theme.palette.grey.A400],
    padding: '1.2rem 2rem 2rem',
    '& > *': {
      margin: theme.spacing(1.5, 0),
    },
  },
  formHeader: {
    marginBottom: theme.typography.pxToRem(2),
    '& $signIn': {
      fontSize: '1.125rem',
    },
    '& $signUpLabel': {
      fontSize: '0.875rem',
      textAlign: 'right',
      '& $newLabel': {
        textTransform: 'Capetalize',
        color: theme.palette.text.secondary,
      },
      '& $createAccount': {
        fontWeight: 500,
        textTransform: 'uppercase',
      },
    },
  },

  signIn: {},
  signUpLabel: {},
  newLabel: {},
  createAccount: {},
  divider: {
    position: 'relative',
    margin: theme.spacing(2.2, 0),
    '& span': {
      position: 'absolute',
      padding: '0 10px',
      top: '50%',
      textTransform: 'uppercase',
      backgroundColor: [theme.palette.grey.A400],
      color: theme.palette.divider,
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  google: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: darken(theme.palette.common.white, 0.1),
    },
    '& .googleLogo': {
      height: theme.typography.pxToRem(18),
      width: theme.typography.pxToRem(18),
      marginRight: theme.typography.pxToRem(8),
    },
  },
  facebook: {
    backgroundColor: theme.palette.facebook.main,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: darken(theme.palette.facebook.main, 0.1),
    },
  },
  joinUs: {
    padding: '1.2rem 1.5rem',
    backgroundColor: theme.palette.secondary.main,
    '& $title': {
      fontWeight: 400,
      fontSize: '1.125rem',
    },
    '& $listItem': {
      paddingTop: '0',
      paddingBottom: '0',
    },
    '& $listText': {
      color: 'rgba(255,255,255,0.87)',
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },
    '& $joinButton': {
      margin: theme.spacing(1, 0, 3),
    },
    '& $listIcon': {
      minWidth: theme.typography.pxToRem(28),
      '& .MuiSvgIcon-root': {
        fontSize: '1.3rem',
      },
    },
  },
  title: {},
  listText: {},
  listItem: {},
  joinButton: {},
  listIcon: {},
}));

const SocialLoginStyled = withStyles((theme) => ({
  root: {
    marginTop: `${theme.spacing(5)} !important`,
  },
  SLGoogle: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.9),
    },
  },
  SLFacebook: {
    backgroundColor: 'rgb(65, 98, 167)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: alpha('rgb(65, 98, 167)', 0.9),
    },
    '& path': {
      fill: theme.palette.common.white,
    },
  },
}))(SocialLogin);

const JoinUsDrawer = ({ open, handleDrawerClose, t }) => {
  const classes = useStyles();
  const whyJoinList = t('fe_es_layout:why_join_ES_list', { returnObjects: true });
  const [snackbar, setSnackbar] = useLayoutSnackbar();
  const { handleError } = useHandleResponse();
  const { moveToERSSOPage } = useSSONavigate();

  const [value, setValue] = React.useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  const handleSignIn = (authenticityToken) => {
    axios
      .post(API.users.signIn, {
        authenticity_token: authenticityToken,
        user: { ...value },
      })
      .then((res) => {
        if (res.data.authenticity_token) {
          handleDrawerClose();
        }
        localStorage.setItem('signin', true);
        localStorage.setItem('signin-msg', res.data.message);

        moveToERSSOPage(res.data.access_token, window.location.href);
      })
      .catch((error) => {
        try {
          switch (error.response.status) {
            case 401:
              if (error.response.data.error_code === 'ERG002') {
                axios.get(API.users.signOut);
              }
              setSnackbar({
                ...snackbar,
                open: true,
                severity: 'error',
                message: error.response.data.message,
              });
              break;
            default:
              handleError(error);
          }
        } catch (e) {
          handleError(error);
        }
      });
  };

  const getToken = () => {
    axios
      .get(API.authenticityToken)
      .then((res) => {
        handleSignIn(res.data.authenticity_token);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (localStorage.getItem('signout')) {
      localStorage.removeItem('signout');
      setSnackbar({
        ...snackbar,
        open: true,
        severity: 'success',
        message: 'You have been signed out successfully!',
      });
    }
  }, []);
  return (
    <>
      <Drawer
        id="join-us-drawer"
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6" gutterBottom={false} className={classes.grow}>
            {t('fe_es_layout:navigation.your_account')}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} noValidate autoComplete="off">
          <Box
            className={classes.formHeader}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" className={classes.signIn}>
              {t('fe_es_layout:navigation.sign_in')}
            </Typography>
            <Typography className={classes.signUpLabel}>
              <span className={classes.newLabel}>
                {t('fe_es_layout:navigation.new')}
                &nbsp;
              </span>
              <Link
                className={classes.createAccount}
                href="/users/sign_up"
                onClick={handleDrawerClose}
                color="primary"
              >
                {t('fe_es_layout:signin.create_account')}
              </Link>
            </Typography>
          </Box>
          <Alert variant="outlined" severity="info">
            Already have an
            {' '}
            <b>EagleRider.com</b>
            {' '}
            user account? You can use it below!
          </Alert>
          <TextField
            fullWidth
            name="email"
            label="Email"
            variant="outlined"
            onChange={handleChange}
            value={value.email}
          />
          <TextField
            type="password"
            fullWidth
            label={t('fe_es_layout:navigation.password')}
            variant="outlined"
            name="password"
            onChange={handleChange}
            value={value.password}
            helperText={(
              <Link
                className={classes.createAccount}
                href="/users/forgot_password"
                onClick={handleDrawerClose}
                color="primary"
              >
                <b>{t('fe_es_layout:signin.forgot_password')}</b>
              </Link>
            )}
          />
          <Button type="button" onClick={getToken} fullWidth variant="contained" color="primary">
            {t('fe_es_layout:navigation.sign_in')}
          </Button>
          {typeof ReactNativeWebView === 'undefined' && (
          <>
            <Divider className={classes.divider} component="div" variant="fullWidth">
              <span>{t('fe_es_layout:navigation.or')}</span>
            </Divider>
            <SocialLoginStyled />
          </>
          )}
        </form>
        <div className={`${classes.joinUs} ${classes.grow}`}>
          <Typography className={classes.title} gutterBottom variant="h6" align="center">
            {t('fe_es_layout:signin.new_to_es')}
          </Typography>
          <Button
            className={classes.joinButton}
            fullWidth
            component={Link}
            onClick={handleDrawerClose}
            href="/users/sign_up"
            variant="contained"
            color="primary"
          >
            {t('fe_es_layout:navigation.join_us')}
          </Button>
          <List dense disablePadding>
            {whyJoinList.length > 0 && (
              <>
                {whyJoinList
                  && typeof whyJoinList === 'object'
                  && whyJoinList.length > 0
                  && whyJoinList.map((item) => (
                    <ListItem key={item.title} className={classes.listItem} dense disableGutters>
                      <ListItemIcon className={classes.listIcon}>
                        <DoneIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.listText} primary={item.title} />
                    </ListItem>
                  ))}
              </>
            )}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default withTranslation(['fe_es_layout'])(JoinUsDrawer);
