import logger from 'loglevel';

class ServerLogger {
  trace(message) {
    if (!process.browser) logger.trace(message);
  }

  debug(message) {
    if (!process.browser) logger.debug(message);
  }

  info(message) {
    if (!process.browser) logger.info(message);
  }

  warn(message) {
    if (!process.browser) logger.warn(message);
  }

  error(message) {
    if (!process.browser) logger.error(message);
  }
}

const serverLogger = new ServerLogger();

export default serverLogger;
