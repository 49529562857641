const { envVars, localeSubpaths } = require('next/config').default().publicRuntimeConfig;

const { API_PROTOCOL, EN_DOMAIN } = envVars;

const getLocalizedUrl = (url, locale) => {
  const subPath = localeSubpaths[locale] ? `/${localeSubpaths[locale]}` : '';

  const baseURL = `${API_PROTOCOL}://${EN_DOMAIN}`;
  return `${baseURL}${subPath}${url}`;
};

export default getLocalizedUrl;
