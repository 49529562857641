import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Link from 'components/shared/Link';
import Image from 'next/image';
import esLogo from '../../public/imgs/eagleshare-logo.svg';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    borderBottomColor: 'transparent',
    left: '0',
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    float: 'left',
    marginLeft: `${theme.spacing(-1.25)} !important`,
  },

  esTxt: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(42),
  },
  logoLink: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const EsLogoNav = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.logoContainer}>
        <Link
          href="/"
          className={classes.logoLink}
        >
          <Image
            width="64"
            height="40"
            unoptimized
            priority
            src={esLogo}
            alt="Eagleshare"
          />
          <span component="span" className={classes.esTxt}>
            eagle
            <b>share</b>
          </span>
        </Link>
      </Box>
    </>
  );
};

export default EsLogoNav;
