import { localeSubpaths } from 'config/i18n';

const useSiteSlug = () => {
  const structureURL = (url, currentLanguage) => {
    if (currentLanguage === 'en') {
      if (url) {
        return `/${url}`;
      }
      return '/';
    }
    if (url) {
      return `/${localeSubpaths[currentLanguage]}/${url}`;
    }
    return `/${localeSubpaths[currentLanguage]}`;
  };

  return { structureURL };
};

export default useSiteSlug;
