import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: [theme.palette.action.hover, '!important'],
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      backgroundColor: [theme.palette.action.selected, '!important'],
    },
  },
}));

const DarkButton = ({ children, className, ...props }) => {
  const classes = useStyles();
  return (
    <MuiButton {...props} className={`${className} ${classes.root}`}>
      {children}
    </MuiButton>
  );
};

export default DarkButton;
