import * as Airbrake from '@airbrake/node';

const { envVars } = require('next/config').default().publicRuntimeConfig;

let airbrake;

export const initAirbrake = () => {
  if (envVars.ENVIRONMENT == 'prelive' || envVars.ENVIRONMENT == 'production') {
    if (envVars.AIRBRAKE_PROJECT_ID && envVars.AIRBRAKE_PROJECT_KEY) {
      airbrake = new Airbrake.Notifier({
        projectId: envVars.AIRBRAKE_PROJECT_ID,
        projectKey: envVars.AIRBRAKE_PROJECT_KEY,
        environment: `${envVars.ENVIRONMENT} [SSR]`,
      });

      airbrake.addFilter((notice) => {
        if (
          notice.errors[0].message.includes('Extension context invalidated')
          || notice.errors[0].backtrace[0]?.file.startsWith('chrome-extension')
          || notice.errors[0].message.includes("undefined is not an object (evaluating 't.length')")
          || notice.errors[0].message.includes('Unexpected token ')
        ) {
          // Ignore errors with this messsagefrom chrome extensions
          return null;
        }
        return notice;
      });
    }
  }
};

export const notifyAirbrake = (error) => {
  if (airbrake) {
    return airbrake.notify(error);
  }
};
