/* eslint-disable no-nested-ternary */

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { ENVIRONMENT } = envVars;

const mapNamedLocaleToLocale = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  es: 'es',
  uk: 'en-GB',
  ca: 'en-CA',
  au: 'en-AU',
  nl: 'nl',
  it: 'it',
  br: 'pt',
  ru: 'ru',
  ar: 'ar',
  kr: 'ko',
  se: 'sv',
  no: 'no',
  dk: 'da',
  jp: 'ja',
  cn: 'zh',
};

// This object allows us to map domain names to locales.
const erDomainLocaleMap = {
  'en-AU':
    ENVIRONMENT == 'development'
      ? 'eaglerider.lcom.au'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.com.au'
        : 'www.eaglerider.com.au',
  zh:
    ENVIRONMENT == 'development'
      ? 'eaglerider.lcn'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.cn'
        : 'www.eaglerider.cn',
  'en-GB':
    ENVIRONMENT == 'development'
      ? 'eaglerider.lco.uk'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.co.uk'
        : 'www.eaglerider.co.uk',
  'en-CA':
    ENVIRONMENT == 'development'
      ? 'eaglerider.lca'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.ca'
        : 'www.eaglerider.ca',
};

const defaultERDomain = ENVIRONMENT == 'development'
  ? 'eaglerider.lcom'
  : ENVIRONMENT == 'prelive'
    ? 'prelive-www.eaglerider.com'
    : 'www.eaglerider.com';

const mapESLocalesToERLocales = {
  de: 'motorrad-mieten',
  fr: 'location-moto',
  es: 'alquiler-de-motos',
  nl: 'motorverhuur',
  it: 'noleggio-moto',
  pt: 'aluguel-de-motos',
  ru: 'ru',
  ar: 'ar',
  ko: 'ko',
  sv: 'sv',
  no: 'motorsykkelleie',
  da: 'motorcykeludlejninger',
  ja: 'ja',
};

module.exports = {
  mapNamedLocaleToLocale,
  erDomainLocaleMap,
  defaultERDomain,
  mapESLocalesToERLocales,
};
