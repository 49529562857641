import React from 'react';
import axios, { API } from 'config/axios';
import { withTranslation } from 'config/i18n';
// Material UI Core
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import dynamic from 'next/dynamic';

// Material UI icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// React components
import DarkButton from 'components/shared/DarkButton';
import {
  useLayoutState,
  useLayoutLoadingSpinner,
  useLayoutSnackbar,
} from 'components/shared/Layout';

import useErrorHandler from 'utils/useErrorHandler';

const CurrenciesDialog = dynamic(
  () => import('components/layout/TopBar/CurrencyDialog'),
);

const useStyles2 = makeStyles((theme) => ({
  paper: {
    width: theme.typography.pxToRem(1200),
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
  },
}));

function CurrencySelector({ t }) {
  const classes = useStyles2();

  const { layoutState } = useLayoutState();
  const [, setLoading] = useLayoutLoadingSpinner();

  const [open, setOpen] = React.useState(false);
  const [shouldRenderDialog, setShouldRenderDialog] = React.useState(false);

  const handleClickCurrency = () => {
    // If modal is not rendered yet,show spinner & only open when all currencies are loaded
    if (!shouldRenderDialog) {
      setLoading(true);
      setShouldRenderDialog(true);
    } else {
      setOpen(true);
    }
  };

  const handleAllCurrenciesLoaded = () => {
    setLoading(false);
    setOpen(true);
  };

  const [snackbar, setSnackbar] = useLayoutSnackbar();

  const changeCurrency = async (newValue) => {
    try {
      await axios.post(API.currencies.change, {
        currency_id: newValue.id,
        authenticity_token: layoutState.authenticity_token,
      });

      window.location.reload();
    } catch (error) {
      setLoading(false);
      useErrorHandler(error, t, [snackbar, setSnackbar]);
    }
  };
  const handleClose = (newValue) => {
    if (newValue) {
      setLoading(true);
      changeCurrency(newValue);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <DarkButton
        variant="contained"
        color="secondary"
        aria-haspopup="true"
        aria-controls="country-menu"
        aria-label="country selector"
        onClick={handleClickCurrency}
        edge="start"
        size="medium"
        endIcon={<KeyboardArrowDownIcon />}
        disableElevation
      >
        <Typography variant="button">{layoutState && layoutState.current_currency ? layoutState.current_currency.symbol : ' '}</Typography>
      </DarkButton>
      {shouldRenderDialog && (
        <CurrenciesDialog
          classes={{
            paper: classes.paper,
          }}
          id="currency-menu"
          maxWidth="xl"
          keepMounted
          open={open}
          onClose={handleClose}
          onAllCurrenciesLoaded={handleAllCurrenciesLoaded}
          t={t}
        />
      )}
    </>
  );
}

export default withTranslation(['fe_es_layout'])(CurrencySelector);
