/* eslint-disable max-len */
import getERUrl from 'utils/getERUrl';
import { i18n } from 'config/i18n';
import getLocalizedUrl from 'utils/getLocalizedUrl';

const useSSONavigate = () => {
  const moveToERSSOPage = (token, returnURL = window.location.origin) => {
    const absoluteReturnUrl = returnURL.startsWith('http')
      ? returnURL
      : getLocalizedUrl(returnURL, i18n.locale);

    window.location.href = getERUrl(
      `/sign_via_access_token?access_token=${token}&return_url=${absoluteReturnUrl}`,
      i18n.language,
    );
  };
  return { moveToERSSOPage };
};

export default useSSONavigate;
