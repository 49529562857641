const CONSTANTS = {
  GOOGLE_SEARCH_URL: (lat, lng) => (`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`),
};

export const AUTH_TOKEN = 'authToken';
export const SSO_IFRAME_ID = 'sso-iframe';

export const SLUGS_TYPES = {
  vehicle_model: 'vehicle_model',
  vehicle_make: 'vehicle_make',
  vehicle_type: 'vehicle_type',
};
export default CONSTANTS;
