import React from 'react';
import CustomTripDialog from './CustomTripsDialog';

const MenuDialogs = ({ item, onClose }) => {
  const [dialog, setDialog] = React.useState({
    customTrip: {
      render: false,
      open: false,
    },

  }); // Only render dialogs into the DOM on demand

  const closeCustomTrip = () => {
    setDialog({
      ...dialog,
      customTrip: {
        ...dialog.customTrip,
        open: false,
      },
    });
    onClose();
  };

  React.useEffect(() => {
    if (item && item.id) {
      switch (item.id) {
        case 'packages_and_tours_custom_trip':
          setDialog((dialog) => ({
            ...dialog,
            customTrip: {
              render: true,
              open: true,
            },
          }));
          break;
      }
    }
  }, [item]);

  return (
    <>
      {dialog.customTrip.render && (
        <CustomTripDialog
          open={dialog.customTrip.open}
          onClose={closeCustomTrip}
        />
      )}
    </>
  );
};

export default MenuDialogs;
