/* eslint-disable no-nested-ternary */
const NextI18Next = require('next-i18next').default;
const {
  localeSubpaths,
  envVars,
} = require('next/config').default().publicRuntimeConfig;
const path = require('path');

const DEFAULT_LOCALE = 'en';

const { ENVIRONMENT } = envVars;
// This object allows us to map domain names to locales.
const domainLocaleMap = {
  // [ENVIRONMENT == 'development'
  //   ? 'eaglerider.lcom.au'
  //   : ENVIRONMENT == 'prelive'
  //     ? 'prelive-www.eaglerider.com.au'
  //     : 'www.eaglerider.com.au']: 'en-AU',
  // [ENVIRONMENT == 'development'
  //   ? 'eaglerider.lcn'
  //   : ENVIRONMENT == 'prelive'
  //     ? 'prelive-www.eaglerider.cn'
  //     : 'www.eaglerider.cn']: 'zh',
  // [ENVIRONMENT == 'development'
  //   ? 'eaglerider.lco.uk'
  //   : ENVIRONMENT == 'prelive'
  //     ? 'prelive-www.eaglerider.co.uk'
  //     : 'www.eaglerider.co.uk']: 'en-GB',
  // [ENVIRONMENT == 'development'
  //   ? 'eaglerider.lca'
  //   : ENVIRONMENT == 'prelive'
  //     ? 'prelive-www.eaglerider.ca'
  //     : 'www.eaglerider.ca']: 'en-CA',
};

const countrySpecificLocales = Object.values(domainLocaleMap);

// Create our custom language detector.
// https://github.com/i18next/i18next-http-middleware#adding-own-detection-functionality
const domainDetector = {
  // We use the name to refer to it later when we want to tell i18next when to use it.
  name: 'domain',
  lookup(req, res, options) {
    let locale = DEFAULT_LOCALE;
    // In the browser, get the hostname from window.location.
    if (typeof window !== 'undefined') {
      // locale = domainLocaleMap[window.location.hostname];
      const subpath = window.location.pathname.split('/')[1];
      if (localeSubpaths[subpath]) {
        locale = subpath;
      } else {
        locale = Object.keys(localeSubpaths).find((lng) => localeSubpaths[lng] === subpath);
      }
    }
    // On the server, get the hostname from the request headers.
    // We use the host header which is available on IncomingMessage.
    // https://nodejs.org/api/http.html#http_class_http_incomingmessage
    // But the host header may include the port so first we take that off, if it exists.
    // else {
    //   const hostname = req.headers.host?.split(':')[0]; // (req.headers.host || "").replace(/:\d+$/, "");
    //   locale = domainLocaleMap[hostname];
    // }
    return locale;
  },
};

const allLanguages = [
  'en',
  'en-CA',
  'en-GB',
  'en-AU',
  'zh',
  'ja',
  'da',
  'no',
  'sv',
  'ko',
  'ar',
  'ru',
  'pt',
  'it',
  'nl',
  'es',
  'fr',
  'de',
];

const nextI18Next = new NextI18Next({
  defaultNS: ['layout'],
  defaultLanguage: DEFAULT_LOCALE,
  fallbackLng: 'en',
  // debug: true,
  localeSubpaths,
  customDetectors: [domainDetector],
  detection: {
    order: ['domain'],
  },
  localeStructure: '%{lng}/%{ns}',
  interpolation: {
    prefix: '%{',
    suffix: '}',
  },
  otherLanguages: allLanguages,
  localePath: path.resolve('./public/static/locales'),
});

module.exports = {
  domainLocaleMap,
  countrySpecificLocales,
  localeSubpaths,
  allLanguages,
  ...nextI18Next,
};
