import axios from 'axios';
import serverLogger from 'utils/serverLogger';
import { getPreferredSeoIdentifer } from 'utils/getPreferredSeoIdentifer';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { API_PROTOCOL } = envVars;
const { API_URL } = envVars;

export const serverFetch = async (ctx, endpointUrl, params = {}, method = 'get', body = null) => {
  let host = null;
  if (envVars.API_HOST) host = envVars.API_HOST;
  else host = ctx.req ? ctx.req.headers.host : window.location.host;

  const url = `${API_PROTOCOL}://${host}${API_URL}${endpointUrl}`;

  const reqUrl = ctx.req ? ctx.req.url : ctx.asPath;
  try {
    serverLogger.debug(`--- Retrieving data from url ${url}`);

    const { data } = await axios({
      url,
      method,
      data: body,
      // manually copy cookie on server,
      // let browser handle it automatically on client
      params: {
        preferred_seo_identifer: getPreferredSeoIdentifer(
          reqUrl,
          ctx.locale,
        ),
        ...params,
      },
      headers: ctx.req
        ? {
          cookie: ctx.req.headers.cookie || '',
          'Accept-Language': ctx.req.i18n.language,
        }
        : undefined,
    });

    serverLogger.debug('--- API data has been retrieved');

    return {
      success: true,
      data: data || {},
    };
  } catch (error) {
    // console.log(`err while getting page data from the server`, error);
    serverLogger.debug('---[API Data Retrieval Error]');
    serverLogger.debug(`\tUrl: ${url}`);
    serverLogger.debug(`\tError Message: ${error.message}`);
    // serverLogger.debug(`\tError Stack Trace:\n ${error.stack}`);

    return {
      success: false,
      error,
      url,
    };
  }
};
