import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import theme from 'config/theme';

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      ...theme.palette.primary,
    },
    secondary: {
      ...theme.palette.secondary,
    },
  },
});

const LightTextField = (props) => {
  const { children, ...otherProps } = props;
  return (
    <ThemeProvider theme={lightTheme}>
      <TextField {...otherProps}>{children}</TextField>
    </ThemeProvider>
  );
};

export default LightTextField;
