import React, {
  useContext, createContext, useEffect, useState,
} from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import dynamic from 'next/dynamic';

import theme from 'config/theme';

import { useRouter } from 'next/router';
import logger from 'loglevel';
import TopBar from '../layout/TopBar';
import MenuBar from '../layout/MenuBar';

const Footer = dynamic(() => import('../layout/Footer'), { ssr: false });

const Error = dynamic(() => import('components/shared/Error'));

const LoadingSpinner = dynamic(() => import('components/shared/LoadingSpinner'), { ssr: false });
const PendingReservations = dynamic(() => import('../layout/PendingReservations'), { ssr: false });
const Snackbar = dynamic(() => import('./SnackBar'), { ssr: false });
const LayoutStateContext = createContext();
const LayoutSnackbarContext = createContext();
const LayoutLoadingSpinnerContext = createContext();
const LayoutErrorContext = createContext();

const darkTheme = createTheme({
  // Dark theme for layout only
  ...theme,
  palette: {
    type: 'dark',
    primary: {
      ...theme.palette.primary,
    },
    secondary: {
      ...theme.palette.secondary,
    },
    warning: {
      ...theme.palette.warning,
    },
    success: {
      ...theme.palette.success,
    },
    error: {
      ...theme.palette.error,
    },
    info: {
      ...theme.palette.info,
    },
    facebook: {
      ...theme.palette.facebook,
    },
    grey: {
      ...theme.palette.grey,
    },
    text: {
      secondary: 'rgba(255, 255, 255, 0.8)',
    },
    background: {
      paper: '#242424',
      default: '#ffffff',
    },
  },
  overrides: {
    ...theme.overrides,
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
    },
    MuiBadge: {
      colorPrimary: {
        color: '#fff',
      },
    },
  },
});

const Layout = ({
  children,
  serverData,
  er,
  pageIdentifier,
  ssrRender,
  headLinks,
  suppressSpinner,
  deviceType,
}) => {
  logger.debug('[debug]>>>Layout');
  const [data, setData] = useState({
    ...serverData,
    isCrawler: ssrRender,
    deviceType,
  });
  const [error, setError] = useState({
    statusCode: false,
    title: '',
    subtitle: '',
  }); // If error, pass status code here like 500
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'error',
    duration: 4000,
  });
  const [loading, setLoading] = React.useState(false);
  const router = useRouter();

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      hreflangData: headLinks,
      isCrawler: ssrRender,
      deviceType,
    }));
  }, [headLinks]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      if (error.statusCode) {
        setError({
          ...error,
          statusCode: false,
        });
      }
      setLoading(!suppressSpinner);
    });
    router.events.on('routeChangeComplete', () => {
      setLoading(false);
    });
    router.events.on('routeChangeError', () => {
      setLoading(false);
    });
  });
  useEffect(() => {
    if (process.browser) {
      setData((prev) => ({
        ...prev,
        ...serverData,
        isCrawler: ssrRender,
        deviceType,
      }));
    }
  }, [serverData]);

  const handleClose = (event, reason) => {
    // On snackbar close
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      alert(
        'Cookies are disabled!\nPlease enable cookies from your browser settings for best experience',
      );
    }
  }, []);

  return (
    <LayoutStateContext.Provider value={{ layoutState: data, updateLayoutState: setData }}>
      <LayoutSnackbarContext.Provider value={[snackbar, setSnackbar]}>
        <LayoutLoadingSpinnerContext.Provider value={[loading, setLoading]}>
          <LayoutErrorContext.Provider value={{ error, setError }}>
            <ThemeProvider theme={darkTheme}>
              {!ssrRender && (
                <>
                  <Snackbar
                    open={snackbar.open}
                    severity={snackbar.severity}
                    message={snackbar.message}
                    autoHideDuration={snackbar.duration}
                    onClose={handleClose}
                  />
                  <LoadingSpinner open={loading} er={er} />
                </>
              )}
              <TopBar />
              <MenuBar er={er} />
              {data && data.current_reservation && data.current_reservation.state ? (
                <PendingReservations />
              ) : null}
            </ThemeProvider>
            <main>
              {error.statusCode && (
                <Error
                  statusCode={error.statusCode}
                  title={error.title}
                  subtitle={error.subtitle}
                />
              )}
              <Box hidden={error.statusCode}>{Object.keys(data || {}).length > 0 && children}</Box>
            </main>

            {!ssrRender && (
              <ThemeProvider theme={darkTheme}>
                <Footer pageIdentifier={pageIdentifier} ssrRender={ssrRender} />
              </ThemeProvider>
            )}
          </LayoutErrorContext.Provider>
        </LayoutLoadingSpinnerContext.Provider>
      </LayoutSnackbarContext.Provider>
    </LayoutStateContext.Provider>
  );
};

export default React.memo(Layout);

export const useLayoutState = () => useContext(LayoutStateContext);
export const useLayoutSnackbar = () => useContext(LayoutSnackbarContext);
export const useLayoutLoadingSpinner = () => useContext(LayoutLoadingSpinnerContext);
export const useLayoutError = () => useContext(LayoutErrorContext);
