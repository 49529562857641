import React from 'react';
import axios, { API } from 'config/axios';
import {
  withTranslation,
  domainLocaleMap,
  countrySpecificLocales,
  localeSubpaths,
} from 'config/i18n';
import dynamic from 'next/dynamic';

import getConfig from 'next/config';
// Material UI Core
import { makeStyles } from '@material-ui/core/styles';
// Material UI icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// React components
import {
  useLayoutState,
} from 'components/shared/Layout';
import useErrorHandler from 'utils/useErrorHandler';
import { useRouter } from 'next/router';

import SvgIcon from '@material-ui/core/SvgIcon';
import Image from 'next/image';
import getCountryFlag from 'common/utils/getCountryFlag';
import DarkButton from '../../shared/DarkButton';

const CountryDialog = dynamic(
  () => import('components/layout/TopBar/CountryDialog'),
);

const useStyles2 = makeStyles((theme) => ({
  paper: {
    width: theme.typography.pxToRem(600),
    maxHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '100vh',
      margin: 0,
      height: '100%',
      borderRadius: 0,
    },
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
  },
  flag: {
    borderRadius: theme.typography.pxToRem(2),
    width: theme.typography.pxToRem(29),
    height: theme.typography.pxToRem(15),
  },
}));

const LanguageSelector = ({ t }) => {
  const classes = useStyles2();
  const router = useRouter();

  const { layoutState } = useLayoutState();

  const {
    publicRuntimeConfig: { envVars },
  } = getConfig();

  const [open, setOpen] = React.useState(false);
  const [country, setCountry] = React.useState({});

  const handleClickCountry = () => {
    setOpen(true);
  };

  const changeCountry = async (newValue) => {
    try {
      const { data } = await axios.post(API.countries.change, newValue);
      if (layoutState.hreflangData) {
        const { url } = layoutState.hreflangData.urls.find((item) => (newValue.language_code === 'en'
          ? item.hreflang === 'x-default'
          : item.hreflang === newValue.language_code));
        window.location.href = url;
      } else if (countrySpecificLocales.indexOf(newValue.language_code) !== -1) {
        // If custom domain language is chosen like CA,UK,AU
        const hostname = Object.keys(domainLocaleMap).find(
          (key) => domainLocaleMap[key] === newValue.language_code,
        );
        window.location.href = `${
          window.location.protocol
        }//${hostname}${getPageURL()}`;
      } else {
        const domainDetector = domainLocaleMap[window.location.hostname];
        if (domainDetector) {
          // If the current language is custom domain language like CA,UK,AU
          // and user selected another language like french or german
          let URL = `${window.location.protocol}//${envVars.EN_DOMAIN}`;
          if (localeSubpaths[newValue.language_code]) {
            // Other languages with subpathes like fr
            URL += `/${localeSubpaths[newValue.language_code]}`;
          }
          URL += window.location.pathname;
          window.location.href = URL;
        } else {
          const pathName = router.asPath;
          const currentLang = layoutState.current_language.code;
          let url;
          if ((currentLang === 'en') && newValue.language_code !== 'en') {
            url = `${window.location.origin}/${
              localeSubpaths[newValue.language_code]
            }${pathName}`;
          } else {
            url = window.location.href;
            let newUrl;
            if (newValue.language_code === 'en') {
              newUrl = url.replace(`/${localeSubpaths[currentLang]}`, '');
            } else {
              newUrl = url.replace(
                `/${localeSubpaths[currentLang]}`,
                `/${localeSubpaths[newValue.language_code]}`,
              );
            }
            url = newUrl;
          }
          window.location.href = url;
        }
      }
    } catch (error) {
      useErrorHandler(error, t, [null, null]);
    }
  };

  React.useEffect(() => {
    if (process.browser && layoutState) {
      setCountry(layoutState.current_country);
    }
  }, [layoutState]);

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setCountry(newValue);
      changeCountry({
        country_id: newValue.id,
        authenticity_token: layoutState.authenticity_token,
        language_code: newValue.language_code,
      });
    }
  };

  const getPageURL = () => {
    // Remove localSubpath if exist and get only the page name
    let pageUrl = window.location.pathname;
    if (pageUrl && localeSubpaths) {
      const urlSegmentsArr = pageUrl.split('/');
      if (urlSegmentsArr && urlSegmentsArr.length > 1) {
        if (
          Object.values(localeSubpaths).findIndex(
            (subpath) => subpath == urlSegmentsArr[1],
          ) !== -1
        ) {
          const newUrl = urlSegmentsArr.slice(2);
          if (newUrl.length > 0) {
            pageUrl = `/${newUrl.join('/')}`;
          }
        }
      }
    }
    return pageUrl;
  };

  const handleCountryInit = (country) => {
    setCountry(country);
  };
  return (
    <>

      <DarkButton
        variant="contained"
        color="secondary"
        aria-haspopup="true"
        aria-controls="country-menu"
        aria-label="country selector"
        onClick={handleClickCountry}
        edge="start"
        size="medium"
        endIcon={<KeyboardArrowDownIcon />}
        disableElevation
      >
        {country && country.iso2 && (
          // <img
          //   src={getCountryFlag(country.iso2)}
          //   alt={country.iso2}
          //   width={39}
          //   height={15}
          //   className={classes.flag}
          // />
          <Image
            src={country?.iso2 && getCountryFlag(country.iso2)}
            alt={`${country.iso2.toUpperCase()} flag image`}
            width={32}
            height={16}
            priority
            className={classes.flag}
          />
        )}
      </DarkButton>
      {open
      && (
      <CountryDialog
        classes={{
          paper: classes.paper,
        }}
        id="country-menu"
        maxWidth="md"
        keepMounted
        open={open}
        onClose={handleClose}
        onCountryInit={handleCountryInit}
        title={t('layout:footer.select_country')}
      />
      )}
    </>
  );
};

export default withTranslation(['layout'])(LanguageSelector);
