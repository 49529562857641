import { SSO_IFRAME_ID } from 'utils/constants';
import { erDomain } from 'data/erDomain';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { envVars },
} = getConfig();
const { API_PROTOCOL } = envVars;

export const getIframe = () => document.getElementById(SSO_IFRAME_ID);
export const singleSignIn = (token) => {
  const iframe = getIframe();
  if (!iframe) {
    throw new Error(
      'Must use SSOIframe component from common/components/SSOIframe.js',
    );
  }
  iframe.contentWindow.postMessage(token, `${API_PROTOCOL}://${erDomain.en}`);
};
export const singleSignOut = () => {
  const iframe = getIframe();
  if (!iframe) {
    throw new Error(
      'Must use SSOIframe component from common/components/SSOIframe.js',
    );
  }
  iframe.contentWindow.postMessage('null', `${API_PROTOCOL}://${erDomain.en}`);
};
