import { erDomainLocaleMap, defaultERDomain, mapESLocalesToERLocales } from 'data/localsMap';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { API_PROTOCOL } = envVars;

const getERUrl = (url, locale = 'en') => {
  const erDomain = erDomainLocaleMap[locale] || defaultERDomain;
  const subPathLocale = mapESLocalesToERLocales[locale]
    ? `/${mapESLocalesToERLocales[locale]}`
    : '';
  return `${API_PROTOCOL}://${erDomain}${subPathLocale}${url}`;
};

export default getERUrl;
