const getCountryFlag = (countryIso, js) => {
  if (js) {
    try {
      const countryCode = countryIso.toLowerCase() === 'uk' ? 'gb' : countryIso;
      return countryCode
        .toUpperCase()
        .replace(/./g, (x) => String.fromCodePoint(x.charCodeAt(0) + 127397));
    } catch (error) {
      return '';
    }
  }

  if (countryIso) {
    return `/svg/flags/${
      countryIso.toLowerCase() === 'gb' ? 'uk' : countryIso.toLowerCase()
    }.svg`;
  }
  return '';
};

export default getCountryFlag;
