/* eslint-disable no-nested-ternary */
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { envVars },
} = getConfig();

const { ENVIRONMENT } = envVars;

export const erDomain = {
  en:
    ENVIRONMENT == 'development'
      ? 'eaglerider.lcom'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.com'
        : 'www.eaglerider.com',
  'en-AU':
    ENVIRONMENT == 'development'
      ? 'eaglerider.lcom.au'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.com.au'
        : 'www.eaglerider.com.au',
  zh:
    ENVIRONMENT == 'development'
      ? 'eaglerider.lcn'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.cn'
        : 'www.eaglerider.cn',
  'en-GB':
    ENVIRONMENT == 'development'
      ? 'eaglerider.lco.uk'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.co.uk'
        : 'www.eaglerider.co.uk',
  'en-CA':
    ENVIRONMENT == 'development'
      ? 'eaglerider.lca'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.ca'
        : 'www.eaglerider.ca',
};
