import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, capitalize } from '@material-ui/core';

import GoogleIcon from 'components/shared/icons/GoogleIcon';
import FacebookIcon from 'components/shared/icons/FacebookIcon';

import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import axios, { API } from 'config/axios';
import { useLayoutSnackbar, useLayoutLoadingSpinner } from 'components/shared/Layout';
import useHandleResponse from 'utils/useHandleResponse';
import { Router, withTranslation } from 'config/i18n';
import clsx from 'clsx';
import useSSONavigate from 'common/hooks/useSSONavigate';

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const useStyles = makeStyles((theme) => ({
  otherLoginBtn: {
    letterSpacing: theme.spacing(0.125),
    textTransform: 'uppercase',
  },
}));

const {
  publicRuntimeConfig: { envVars },
} = getConfig();

const WindowOpener = dynamic(() => import('components/shared/WindowOpener'), {
  ssr: false,
});

const IDB_PROVIDERS = {
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
};

const SocialLogin = (props) => {
  const { t, classes: styles = {} } = props;
  const classes = useStyles();
  const [, setLoading] = useLayoutLoadingSpinner();
  const [snackbar, setSnackbar] = useLayoutSnackbar();
  const { handleError } = useHandleResponse();
  const { moveToERSSOPage } = useSSONavigate();

  const [providerData, SetProviderData] = useState(null);

  // eslint-disable-next-line max-len
  const getHostedUIThirdpartyUrl = (provider, returnUrl) => `${envVars.COGNITO_USER_POOL_DOMAIN}/oauth2/authorize?identity_provider=${provider}&redirect_uri=${returnUrl}&response_type=CODE&client_id=${envVars.COGNITO_CLIENT_ID}`;

  const getToken = () => axios
    .get(API.authenticityToken)
    .then(
      (res) => res.data.authenticity_token,
    )
    .catch((error) => {
      handleError(error);
    });
  const signInUser = (signParams) => axios.post(API.users.thirdParty, {
    identity_provider: capitalize(signParams.provider),
    redirect_uri: signParams.redirectUri,
    code: signParams.code,
    authenticity_token: signParams.authenticityToken,
  });
  const mapProviderToRedirectUri = useMemo(() => {
    if (typeof window === 'undefined') return {};

    return {
      google: `${window.location.origin}/auth/google`,
      facebook: `${window.location.origin}/auth/facebook`,
    };
  }, []);
  const signInViaThirdParty = async () => {
    try {
      setLoading(true);

      const authenticityToken = await getToken();
      const res = await signInUser({
        provider: providerData.provider,
        redirectUri: mapProviderToRedirectUri[providerData.provider],
        code: providerData.code,
        authenticity_token: authenticityToken,
      });
      let path = null;
      if (Router?.query?.return_url) {
        path = Router.query.return_url;
      } else {
        path = '/';
      }

      moveToERSSOPage(res.data.access_token, path);
    } catch (error) {
      try {
        switch (error.response.status) {
          case 401:
            setSnackbar({
              ...snackbar,
              open: true,
              severity: 'error',
              message: error.response.data.message,
            });
            break;
          default:
            handleError(error);
        }
      } catch (e) {
        handleError(error);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const providerResponse = (err, res) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log(res, 'error');
    }

    if (res.code) {
      SetProviderData(res);
    } else {
      // eslint-disable-next-line no-alert
      alert('something wrong please try again ');
    }
  };
  useEffect(() => {
    providerData && signInViaThirdParty();
  }, [providerData]);
  return (
    <Box className={clsx(styles.root)}>
      <Box mb={2}>
        <WindowOpener
          url={getHostedUIThirdpartyUrl(
            IDB_PROVIDERS.GOOGLE,
            `${window.location.origin}/auth/google`,
          )}
          bridge={providerResponse}
          width={600}
          height={600}
        >
          <Button
            className={clsx(classes.otherLoginBtn, styles.SLGoogle)}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            <Box component="span" lineHeight="10px" mr={1}>
              <GoogleIcon />
            </Box>
            {t('fe_es_layout:signin.sign_in_with_google')}
          </Button>
        </WindowOpener>
      </Box>
      <Box mb={2}>
        <WindowOpener
          url={getHostedUIThirdpartyUrl(
            IDB_PROVIDERS.FACEBOOK,
            `${window.location.origin}/auth/facebook`,
          )}
          bridge={providerResponse}
          width={600}
          height={600}
        >
          <Button
            className={clsx(classes.otherLoginBtn, styles.SLFacebook)}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            <Box component="span" lineHeight="10px" mr={1}>
              <FacebookIcon />
            </Box>
            {t('fe_es_layout:signin.sign_in_with_facebook')}
          </Button>
        </WindowOpener>
      </Box>
    </Box>
  );
};

export default withTranslation('fe_es_auth')(SocialLogin);
