const getQueryCSR = () => {
  const queries = {};
  window.location.search
    .slice(1)
    .split('&')
    .forEach((queryItem) => {
      const [key, ...value] = queryItem.split('=');
      // sometimes the query have "=" so we need to rejoin again
      const joinedValue = value.join('=');
      queries[key] = joinedValue;
    });
  return queries;
};

export default getQueryCSR;
