import { useLayoutState } from 'components/shared/Layout';
import { useState, useEffect } from 'react';
import { i18n } from 'config/i18n';

const MenuItems = [

  {
    id: 'check_in',
    text: 'layout:navigation.check_in',
    href: '/check-in',
  },

];
/** *
 * Custom hook instead of static arrays because we have data that is fetched dynamically
 * from the API and to separate this logic from the components
 ** */
const useHeaderMenuItems = (for_mobile) => {
  const { layoutState } = useLayoutState();

  const [menuItems, setMenuItems] = useState(MenuItems);
  useEffect(() => {
    const newItems = MenuItems.slice();
    if (
      layoutState
      && layoutState.featured_rentals
      && layoutState.featured_rentals.header
    ) {
      const outerArray = []; // It will be like [[...,...],[...,...],[...,...]]
      for (let i = 0; i < layoutState.featured_rentals.header.length; i += 2) {
        let innerArray = [layoutState.featured_rentals.header[i]];
        if (i + 1 < layoutState.featured_rentals.header.length) {
          innerArray.push(layoutState.featured_rentals.header[i + 1]);
        }
        innerArray = innerArray.map((rental) => ({
          text: `rentals:${rental.translation_key}_rentals`,
          href: `/${rental.slug}`,
          is_harley_davidson: rental.is_harley_davidson,
        }));
        outerArray.push(innerArray);
      }
    }

    setMenuItems(newItems);
  }, [layoutState, i18n.language]);

  return menuItems;
};

export default useHeaderMenuItems;
